import * as React from 'react'
import { PC, SP } from '../utils/media-query'
const styles = require('scss/suggestion.module.scss')

const messages = [
  '「売上向上や業務改善を改善できるシステムを作りたい…」',
  '「そもそも何を作ったら良いのかが分からない…」',
  '「作りたいシステムは漠然と決まっているが、仕様や要件が決まっていない…」',
]

import CheckCircle from '../svg/CheckCircle'

export default class Suggestion extends React.Component {
  render() {
    return (
      <section className={styles.suggestion}>
        <div className={styles.wrapper}>
          <div className="container">
            <div className={styles.title}>
              <SP>
                <h2>
                  「そもそも何を作ったらいいのか分からない」
                  <br />
                  場合でも、 お気軽にご相談ください。
                </h2>
              </SP>
              <PC>
                <h2>
                  「そもそも何を作ったらいいのか分からない」場合でも、
                  <br />
                  お気軽にご相談ください。
                </h2>
              </PC>
            </div>
            <div className={styles.overview}>
              {messages.map((message, index) => {
                return (
                  <p key={index}>
                    <CheckCircle />
                    <span>{message}</span>
                  </p>
                )
              })}
            </div>
            <div className={styles.description}>
              <SP>
                <p>
                  弊社ではこういった、
                  <span className="under-yellow">
                    問題提起から要件定義のお悩みまで幅広いご相談にも乗らせていただきます。
                  </span>
                  <br />
                  システムは手段であり目的ではありません。お客様が抱える真のお悩みに耳を傾け、お客様と同じ目線で問題を捉えてこそ、最前最適なご提案ができると信じております。
                  <br />
                  単なるシステム構築ではなく「問題の根本を解消するシステム」を実現します。
                </p>
              </SP>
              <PC>
                <p>
                  弊社ではこういった、
                  <span className="under-yellow">
                    問題提起から要件定義のお悩みまで幅広いご相談にも乗らせていただきます。
                  </span>
                  <br />
                  システムは手段であり目的ではありません。お客様が抱える真のお悩みに耳を傾け、お客様と同じ目線で問題を捉えてこそ、
                  <br />
                  最前最適なご提案ができると信じております。単なるシステム構築ではなく「問題の根本を解消するシステム」を実現します。
                  <br />
                </p>
              </PC>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
