import * as React from 'react'
import { PC, SP } from '../utils/media-query'
import Button from '../atoms/TheButton'
const style = require('scss/form.module.scss')
const inputs = [
  {
    type: 'text',
    label: '法人名',
    name: 'llc',
    required: false,
  },
  {
    type: 'text',
    label: '担当者名',
    name: 'name',
    required: true,
  },
  {
    type: 'text',
    label: '担当者名（ふりがな）',
    name: 'kana',
    required: false,
  },
  {
    type: 'text',
    label: '電話番号',
    name: 'tel',
    required: true,
  },
  {
    type: 'text',
    label: 'メールアドレス',
    name: 'email',
    required: true,
  },
]

export const Form = (): JSX.Element => {
  return (
    <div className={style.contactForm}>
      <form
        action="/form-done"
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input
          type="hidden"
          name="form-name"
          value="contact"
        />
        {Array(inputs.length)
          .fill(null)
          .map((_, index) => {
            const data = inputs[index]
            const label = data.label
            const type = data.type
            const name = data.name
            const required = data.required
            return (
              <div
                className={style.formElement}
                key={`form-${index}`}
              >
                <span className={style.formLabel}>
                  {label}
                  {required ? (
                    <abbr title="required">＊</abbr>
                  ) : undefined}
                </span>
                <br />
                <input
                  type={type}
                  required={required}
                  name={name}
                  className={style.formInput}
                  maxLength={40}
                />
              </div>
            )
          })}
        <div className={style.formElement}>
          <span className={style.formLabel}>
            お問い合わせ内容
            <abbr title="required">＊</abbr>
          </span>
          <br />
          <textarea
            name="content"
            className={style.formInput}
            maxLength={400}
          />
          <div className={style.formButton}>
            <PC>
              <Button type="square" elevation="4" submit>
                送信
              </Button>
            </PC>
            <SP>
              <Button
                type="square"
                elevation="4"
                submit
                size="responsive"
              >
                送信
              </Button>
            </SP>
          </div>
        </div>
      </form>
    </div>
  )
}
