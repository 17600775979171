import * as React from 'react'
import { PC, SP } from '../utils/media-query'
const styles = require('scss/solutionsCard.module.scss')

type Props = {
  index: number
  indexImage: string
  image: string
  title: JSX.Element
  description: JSX.Element
}
const SolutionsCard = (props: Props) => {
  return (
    <div className={styles.solutionsCard}>
      <SP>
        <div className={styles.image}>
          <img
            src={props.image}
            alt={props.image}
            className={styles[`image${props.index}`]}
          />
        </div>
        <div className={styles.descriptionHead}>
          <img
            src={props.indexImage}
            alt={props.indexImage}
          />
          {props.title}
        </div>
        <div className={styles.description}>
          {props.description}
        </div>
      </SP>
      <PC>
        <div className={styles.indexImage}>
          <img
            src={props.indexImage}
            alt={props.indexImage}
            className={styles.indexImage}
          />
        </div>
        <div className={styles.description}>
          {props.title}
          {props.description}
        </div>
        <div className={styles.image}>
          <img
            src={props.image}
            alt={props.image}
            className={styles[`image${props.index}`]}
          />
        </div>
      </PC>
    </div>
  )
}
export default SolutionsCard
