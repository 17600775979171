import * as React from 'react'
import { PC, SP } from '../utils/media-query'
import Button from '../atoms/TheButton'
import MyBorder from '../atoms/MyBorder'
const style = require('scss/services.module.scss')

const topDescriptions = [
  <p>
    自社
    <PC>
      <span className="under-yellow bold">
        オリジナルの顧客管理システム
      </span>
    </PC>
    <SP>
      <span className="under-yellow-thin bold">
        オリジナルの顧客管理システム
      </span>
    </SP>
    を作りたい
  </p>,
  <p>
    現在使っているECサイトの管理システムに、
    <PC>
      <span className="under-yellow bold">
        新しい決済機能
      </span>
    </PC>
    <SP>
      <span className="under-yellow-thin bold">
        新しい決済機能
      </span>
    </SP>
    を追加したい。
  </p>,
  <p>
    エクセルによる在庫処分が限界なので、
    <PC>
      <span className="under-yellow bold">
        スムーズに在庫管理
      </span>
    </PC>
    <SP>
      <span className="under-yellow-thin bold">
        スムーズに在庫管理
      </span>
    </SP>
    できる何らかのシステムを作りたい。
  </p>,
]
const bottomDescriptions = [
  <p>
    既存システムのメンテナンスを行う人がいなくなったため、
    <PC>
      <span className="under-yellow bold">
        少しの間、改修や点検
      </span>
    </PC>
    <SP>
      <span className="under-yellow-thin bold">
        少しの間、改修や点検
      </span>
    </SP>
    をしてもらいたい。
  </p>,
  <p>
    業務向上や業務改善のために何らかのシステムを導入すべきだが、
    <PC>
      <span className="under-yellow bold">
        具体的な仕様は決まっていない
      </span>
    </PC>
    <SP>
      <span className="under-yellow-thin bold">
        具体的な仕様は決まっていない
      </span>
    </SP>
    。
  </p>,
  <p>
    現在進めている開発スピードを上げるべく、
    <PC>
      <span className="under-yellow bold">
        何人かエンジニアを追加したい
      </span>
    </PC>
    <SP>
      <span className="under-yellow-thin bold">
        何人かエンジニアを追加したい
      </span>
    </SP>
    。
  </p>,
]
export default class Contact extends React.Component {
  render() {
    return (
      <section>
        <div className={style.servicesWrapper}>
          <div className="container">
            <h2 className={style.title}>
              ニチコマのサービス
            </h2>
            <div
              className={`${style.Wrapper} ${style.topWrapper}`}
            >
              <div className={style.signboard}>
                成果型契約
              </div>
              <div className={style.subTitle}>
                <MyBorder
                  message="こんな方におすすめ"
                  color="#063757"
                />
              </div>
              <div className={style.cardsWrapper}>
                {Array(3)
                  .fill(null)
                  .map((_, index) => {
                    const pcImage = require(`images/pc/services_${index}.png`)
                    const spImage = require(`images/sp/services_${index}.png`)

                    const description =
                      topDescriptions[index]
                    return (
                      <div
                        className={`${style.problemCard} elevation-1 `}
                        key={`problemTopCard-${index}`}
                      >
                        <div
                          className={style.topImageWrapper}
                        >
                          <PC>
                            <img
                              src={pcImage}
                              alt={`image-${index + 1}`}
                              className={
                                style[`image${index + 1}`]
                              }
                            />
                          </PC>
                          <SP>
                            <img
                              src={spImage}
                              alt={`image-${index + 1}`}
                              className={
                                style[`image${index + 1}`]
                              }
                            />
                          </SP>
                        </div>
                        <div className={style.description}>
                          {description}
                        </div>
                      </div>
                    )
                  })}
              </div>
              <div className={style.sentence}>
                <SP>
                  <p>
                    成果型契約（請負契約）は実現したいシステムが完成するまでお手伝いさせていただくサービスです。
                    <br />
                    <br />
                    「作りたいシステムが既に決まっている方」に最適です。開発が終わらないまま契約が終了することはございません。契約期間中は都度お打ち合わせ・すり合わせが可能なため、すれ違いや認識のズレも生じません。最初にお見積もりさせていただいただく金額の半分を着手前にいただき、残りの半分をシステム完成後にいただいております。
                  </p>
                </SP>
                <PC>
                  <p>
                    成果型契約（請負契約）は実現したいシステムが完成するまでお手伝いさせていただくサービスです。
                    「作りたいシステムが既に決まっている方」に最適です。開発が終わらないまま契約が終了することはございません。契約期間中は都度お打ち合わせ・すり合わせが可能なため、すれ違いや認識のズレも生じません。最初にお見積もりさせていただいただく金額の半分を着手前にいただき、残りの半分をシステム完成後にいただいております。
                  </p>
                </PC>
              </div>
              <div className={style.buttonWrapper}>
                <PC>
                  <Button elevation="1" icon to="#contact">
                    詳細・お見積り
                  </Button>
                </PC>
                <SP>
                  <Button
                    elevation="1"
                    icon
                    to="#contact"
                    size="responsive"
                  >
                    詳細・お見積り
                  </Button>
                </SP>
              </div>
            </div>
            <div
              className={`${style.Wrapper} ${style.bottomWrapper}`}
            >
              <div className={style.signboard}>
                時間契約
              </div>
              <div className={style.subTitle}>
                <MyBorder
                  message="こんな方におすすめ"
                  color="#063757"
                />
              </div>
              <div className={style.cardsWrapper}>
                {Array(3)
                  .fill(null)
                  .map((_, index) => {
                    const pcImage = require(`images/pc/services_${
                      index + 3
                    }.png`)
                    const spImage = require(`images/sp/services_${
                      index + 3
                    }.png`)

                    const description =
                      bottomDescriptions[index]
                    return (
                      <div
                        className={`${style.problemCard} elevation-1 `}
                        key={`problemBottomCard-${index}`}
                      >
                        <div
                          className={
                            style.bottomImageWrapper
                          }
                        >
                          <PC>
                            <img
                              src={pcImage}
                              alt={`image-${index + 4}`}
                              className={
                                style[`image${index + 4}`]
                              }
                            />
                          </PC>
                          <SP>
                            <img
                              src={spImage}
                              alt={`image-${index + 4}`}
                              className={
                                style[`image${index + 4}`]
                              }
                            />
                          </SP>
                        </div>
                        <div className={style.description}>
                          {description}
                        </div>
                      </div>
                    )
                  })}
              </div>
              <div className={style.sentence}>
                <SP>
                  <p>
                    時間契約（準委任契約）は、あらかじめ決めていただいた期間を、弊社のエンジニアが稼働するサービスです。
                    <br />
                    <br />
                    「システム開発による改善を実現しようとは考えているものの、どのような機能を持ったシステムを作れば良いのかはっきりと決まっていない」「決まった期間中だけ稼働して欲しい」といった方に最適のプランです。請負契約とは違い、仕様や要件の変更が途中何度も可能で、かつ費用をよりお安く抑えられるのがメリットです。
                  </p>
                </SP>
                <PC>
                  <p>
                    時間契約（準委任契約）は、あらかじめ決めていただいた期間を、弊社のエンジニアが稼働するサービスです。
                    「システム開発による改善を実現しようとは考えているものの、どのような機能を持ったシステムを作れば良いのかはっきりと決まっていない」
                    「決まった期間中だけ稼働して欲しい」といった方に最適のプランです。請負契約とは違い、仕様や要件の変更が途中何度も可能で、かつ費用をよりお安く抑えられるのがメリットです。
                  </p>
                </PC>
              </div>
              <div className={style.buttonWrapper}>
                <PC>
                  <Button elevation="1" icon to="#contact">
                    詳細・お見積り
                  </Button>
                </PC>
                <SP>
                  <Button
                    elevation="1"
                    icon
                    to="#contact"
                    size="responsive"
                  >
                    詳細・お見積り
                  </Button>
                </SP>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
