import * as React from 'react'
import { PC, SP } from '../utils/media-query'
import HL from '../atoms/HorizontalLine'
import SolutionsCard from '../molecules/SolutionsCard'
// import Button from '../components/atoms/TheButton'
const styles = require('scss/solutions.module.scss')

const spTitles = [
  () => {
    return (
      <h3>
        {`自社オリジナルの\n在庫管理システムを開発。`}
      </h3>
    )
  },
  () => {
    return (
      <h3>{`自社製品をウェブでも\n販売可能となった。`}</h3>
    )
  },
  () => {
    return (
      <h3>
        {`自社の勤務形態に\n最適化された\n勤怠管理システムを導入。`}
      </h3>
    )
  },
]

const pcTitles = [
  () => {
    return (
      <h3>
        {`自社オリジナルの\n在庫管理システムを開発。`}
      </h3>
    )
  },
  () => {
    return (
      <h3>{`自社製品をウェブでも\n販売可能となった。`}</h3>
    )
  },
  () => {
    return (
      <h3>
        {`自社の勤務形態に最適化された\n勤怠管理システムを導入。`}
      </h3>
    )
  },
]

const descriptions = [
  () => {
    return (
      <p>
        {`最小限の手入力で正確な在庫管理が可能となり、\n`}
        <span>入力ミスや漏れは一切なくなった</span>
      </p>
    )
  },
  () => {
    return (
      <p>
        {`外回り営業ではリーチできなかったリストや\n顧客にもアプローチでき、`}
        <span>売り上げが2倍に！</span>
      </p>
    )
  },
  () => {
    return (
      <p>
        {`何もせずとも社員の勤怠がデータとして\n残るようになり、`}
        <span>管理が大幅に楽になった！</span>
      </p>
    )
  },
]

class Solutions extends React.Component {
  render() {
    return (
      <section className={styles.solutions}>
        <div className={styles.head}>
          <PC>
            <HL size="2px" color="white" width="13rem">
              <h2 className="mx-5">
                弊社の
                <span className={styles.dot}>
                  システム開発
                </span>
                を通して解決いたします
              </h2>
            </HL>
          </PC>
          <SP>
            <h2 className="mx-5">
              弊社の
              <span className={styles.dot}>
                システム開発
              </span>
              を通して解決いたします
            </h2>
          </SP>
        </div>
        <div className={styles.content}>
          {Array(3)
            .fill(null)
            .map((_, index) => {
              const spIndexImage = require(`images/sp/solutions_index_${index}.png`)
              const pcIndexImage = require(`images/pc/solutions_index_${index}.png`)
              const spImage = require(`images/sp/solutions_${index}.png`)
              const pcImage = require(`images/pc/solutions_${index}.png`)
              return (
                <React.Fragment key={index}>
                  <SP key={index}>
                    <SolutionsCard
                      index={index}
                      indexImage={spIndexImage}
                      image={spImage}
                      title={spTitles[index]()}
                      description={descriptions[index]()}
                    ></SolutionsCard>
                  </SP>
                  <PC>
                    <SolutionsCard
                      index={index}
                      indexImage={pcIndexImage}
                      image={pcImage}
                      title={pcTitles[index]()}
                      description={descriptions[index]()}
                    ></SolutionsCard>
                  </PC>
                </React.Fragment>
              )
            })}
        </div>
        <div className={styles.projectionWrapper}>
          <div className={styles.solutionsProjection}>
            <span
              className={styles.solutionsProjectionAfter}
            ></span>
          </div>
        </div>
        {/* <Button
          type="square"
          size="small"
          icon
          to="https://nichicoma.co.jp/price"
        />詳しくはこちら
        </Button> */}
      </section>
    )
  }
}

export default Solutions
