import * as React from 'react'
import { PC, SP } from '../utils/media-query'
import { Link } from 'gatsby'
import { Form } from './Form'
const style = require('scss/contact.module.scss')

export default class Contact extends React.Component {
  render() {
    return (
      <section>
        <div id="contact" className={style.contactHeader}>
          <h2 className={style.title}>
            お問い合わせ・ご相談
          </h2>
          <div className={style.subTitle}>
            <PC>
              どういったご用件・ご相談でも承りますので、お気軽にご連絡くださいませ。
            </PC>
            <SP>
              どういったご要件・ご相談でも承りますので、
              <br />
              お気軽にご連絡くださいませ。
            </SP>
          </div>
          {/* <div className={style.recruitment}>
            <PC>
              営業パートナーを探しております。
              <br />
              詳しくは<Link to="/news">こちら</Link>
            </PC>
            <SP>
              営業パートナーを探しております。
              <br />
              詳しくは<Link to="/news">こちら</Link>
            </SP>
          </div> */}
        </div>
        <Form />
      </section>
    )
  }
}
