import * as React from 'react'
const styles = require('scss/myBorder.module.scss')
export interface MyBorderProps {
  message: string
  large?: boolean
  color: string
}

const MyBorder = (props: MyBorderProps) => {
  const borderSize = props.large ? '3px' : '2px'
  const triangleSize = props.large ? 'large' : 'small'
  const borderStyle = {
    borderBottom: `${borderSize} solid ${props.color}`,
  }
  return (
    <div className={styles.myBorder}>
      <p style={borderStyle}>
        {props.message}
        <span
          className={styles[`triangleTop__${triangleSize}`]}
        />
        <span
          className={
            styles[`triangleBottom__${triangleSize}`]
          }
        />
      </p>
    </div>
  )
}

MyBorder.DefaultProps = {
  large: false,
}

export default MyBorder
