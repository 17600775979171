import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Header from '../components/organisms/Header'
import Top from 'organisms/Top'
import Problems from 'organisms/Problems'
import Solutions from 'organisms/Solutions'
import Suggestion from 'organisms/Suggestion'
import StrongPoint from 'organisms/StrongPoint'
import Services from '../components/organisms/Services'
import AboutUs from '../components/organisms/AboutUs'
import Contact from '../components/organisms/Contact'
import Footer from '../components/organisms/Footer'

import { library } from '@fortawesome/fontawesome-svg-core' //fontAwesomeのコアファイル
import { fab } from '@fortawesome/free-brands-svg-icons' //fontAwesomeのbrandアイコンのインポート
import { fas } from '@fortawesome/free-solid-svg-icons' //fontAwesomeのsolidアイコンのインポート
import { far } from '@fortawesome/free-regular-svg-icons' //fontAwesomeのregularアイコンのインポート

library.add(fab, fas, far)

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div className="body">
          <div id="wrapper">
            <Header
              isTopPage={
                this.props.location.pathname === '/'
              }
            />
            <Top />
            <Problems />
            <Solutions />
            <Suggestion />
            <StrongPoint />
            <Services />
            <AboutUs />
            <Contact />
            <Footer />
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
