import * as React from 'react'
import { SP, PC } from '../utils/media-query'
const styles = require('scss/estimateList.module.scss')

interface EstimateHeading {
  developmentCost: string
  costOfChange: string
  redevelopmentCost: string
  totalCost: string
}

export interface EstimateListProps {
  contents: Array<EstimateHeading>
}

const EstimateList = (props: EstimateListProps) => {
  return (
    <>
      <div className={styles.estimate}>
        <PC>
          <table className={styles.companies}>
            <tbody>
              <tr>
                <td className={styles.empty}></td>
              </tr>
              <tr>
                <td>ニチコマ</td>
              </tr>
              <tr>
                <td>他社</td>
              </tr>
            </tbody>
          </table>
          <table className={styles.prices}>
            <thead>
              <tr>
                <th className={styles.developmentCost}>
                  開発費用
                  <span />
                </th>
                <th className={styles.costOfChange}>
                  + 機能変更費用
                  <span />
                </th>
                <th className={styles.redevelopmentCost}>
                  + 再開発費用
                  <span />
                </th>
                <th className={styles.totalCost}>
                  年間合計費用
                </th>
              </tr>
            </thead>
            <tbody>
              {props.contents.map((content, index) => {
                return (
                  <tr key={index}>
                    <td>{content.developmentCost}</td>
                    <td className={styles.costOfChange}>
                      {content.costOfChange}
                    </td>
                    <td
                      className={styles.redevelopmentCost}
                    >
                      {content.redevelopmentCost}
                    </td>
                    <td className={styles.totalCost}>
                      <span
                        className={
                          styles[`totalCost__${index}`]
                        }
                      >
                        {content.totalCost}
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </PC>
        <SP>
          <table className={styles.companies}>
            <tbody>
              <tr>
                <td className={styles.empty}></td>
                <td>ニチコマ</td>
                <td>他社</td>
              </tr>
            </tbody>
          </table>
          <table className={styles.prices}>
            <tbody>
              <tr>
                <th className={styles.developmentCost}>
                  {`開発\n費用`}
                  <span />
                </th>
                <td>{props.contents[0].developmentCost}</td>
                <td>{props.contents[1].developmentCost}</td>
              </tr>
              <tr>
                <th className={styles.costOfChange}>
                  {`+\n機能\n変更\n費用`}
                  <span />
                </th>
                <td className={styles.costOfChange}>
                  {props.contents[0].costOfChange}
                </td>
                <td className={styles.costOfChange}>
                  {props.contents[1].costOfChange}
                </td>
              </tr>
              <tr>
                <th className={styles.redevelopmentCost}>
                  {`+\n再開発\n費用`}
                  <span />
                </th>
                <td className={styles.redevelopmentCost}>
                  {props.contents[0].redevelopmentCost}
                </td>
                <td className={styles.redevelopmentCost}>
                  {props.contents[1].redevelopmentCost}
                </td>
              </tr>
              <tr>
                <th className={styles.totalCost}>
                  {`年間\n合計\n費用`}
                </th>
                <td className={styles.totalCost}>
                  <span className={styles[`totalCost__0`]}>
                    {props.contents[0].totalCost}
                  </span>
                </td>
                <td className={styles.totalCost}>
                  <span>{props.contents[1].totalCost}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </SP>
      </div>
    </>
  )
}

export default EstimateList
