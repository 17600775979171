import * as React from 'react'
import { Link } from 'gatsby'
const style = require('scss/ImageCard.module.scss')
interface Props {
  title?: string
  image: string
  webP?: string
  to: string
  width?: number
  height?: number
  maxWidth?: number
  maxHeight?: number
}
interface State {}
class ImageCard extends React.Component<Props, State> {
  render() {
    return (
      <Link to={this.props.to}>
        <div
          className={`${style.imageCard} img_hover`}
          style={{
            width: this.props.width || '100%',
            height: this.props.height || '100%',
            maxWidth: this.props.maxWidth || '100%',
            maxHeight: this.props.maxHeight || '100%',
          }}
        >
          <div className={style.imageWrapper}>
            <picture>
              <source
                type="image/webp"
                srcSet={this.props.webP}
              />
              <img
                src={this.props.image}
                alt={this.props.title}
                className={style.coverImage}
                width={this.props.width || '100%'}
                height={this.props.height || '100%'}
              />
            </picture>
          </div>
          <div className={style.cardText}>
            {this.props.title}
          </div>
        </div>
      </Link>
    )
  }
}
export default ImageCard
