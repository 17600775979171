import * as React from 'react'
import { Link } from 'gatsby'
const style = require('scss/footer.module.scss')
const classNames = require('classnames')
class AboutUs extends React.Component {
  render() {
    return (
      <footer>
        <div className={style.content}>
          <div
            className={`${style.footerLeft} ${style.footerContent}`}
          >
            <div className={style.title}>
              <h3>ニチコマ合同会社</h3>
            </div>
            <div className={style.address}>
              <p>〒203-0012</p>
              <p>東京都東久留米市浅間町3丁目5-11</p>
            </div>
          </div>
          <div
            className={`${style.footerCenter} ${style.footerContent}`}
          >
            <div className={style.companyProfile}>
              <ul>
                <li className={style.caption}>
                  <h3>企業概要</h3>
                </li>
                <li>
                  <Link to="/ceo-message">
                    - 代表メッセージ
                  </Link>
                </li>
                <li>
                  <Link to="/about-llc">
                    - 合同会社とは？
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={`${style.footerRight} ${style.footerContent}`}
          >
            <ul>
              <li className={style.caption}>
                <h3>採用情報</h3>
              </li>
              <li>
                <Link to="/recruit/web-engineer">
                  - Webエンジニアの採用情報
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={classNames(style.info, 'centerText')}
        >
          <small>nichicoma©︎All Right Reserved.</small>
        </div>
      </footer>
    )
  }
}
export default AboutUs
