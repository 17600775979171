import * as React from 'react'
import { PC, SP } from '../utils/media-query'
import HL from '../atoms/HorizontalLine'
import ProblemsCard from '../molecules/ProblemsCard'
const styles = require('scss/problems.module.scss')

const texts = [
  'エクセルで在庫管理をしているが、入力ミスによる無駄な発注や業務が不定期で発生し、手作業の限界を感じている・・・',
  '商品やサービスを自社のウェブサイトからでも販売できるようにしたいが、何から始めれば良いのか分からない・・・',
  'リモートワークを導入したが、社員の勤務時間や残業時間は全てメール報告になっており、勤怠管理が非常に面倒くさい・・・',
]
const title = (
  <h2 className={styles.title}>
    こんな
    <span className={styles.span}>お悩み</span>
    、ありませんか？
  </h2>
)

export default class Problems extends React.Component {
  render() {
    return (
      <section>
        <div className={styles.problems}>
          <div className="container">
            <div className={styles.head}>
              <SP>
                <HL
                  size="1px"
                  width="clamp(1.5rem, 5vw, 10rem)"
                >
                  {title}
                </HL>
              </SP>
              <PC>
                <HL
                  size="2px"
                  width="clamp(3rem, 10vw, 16rem)"
                >
                  {title}
                </HL>
              </PC>
            </div>
            <div className={styles.content}>
              {texts.map((text, index) => {
                const spImage = require(`images/sp/problems_${index}.png`)
                const pcImage = require(`images/pc/problems_${index}.png`)
                return (
                  <React.Fragment key={index}>
                    <SP>
                      <ProblemsCard
                        image={spImage}
                        text={text}
                      ></ProblemsCard>
                    </SP>
                    <PC>
                      <ProblemsCard
                        image={pcImage}
                        text={text}
                      ></ProblemsCard>
                    </PC>
                  </React.Fragment>
                )
              })}
            </div>
            <span className={styles.problemsProjection} />
          </div>
        </div>
      </section>
    )
  }
}
