import * as React from 'react'
import { SP, PC } from '../utils/media-query'
const styles = require('scss/strongPointCard.module.scss')

export interface StrongPointCardProps {
  title?: JSX.Element
  subTitle?: string
  description?: string
  image?: string
  index: number
  children?: React.ReactChild
}

const StrongPointCard = (props: StrongPointCardProps) => {
  const spImage = require(`images/sp/${props.image}.png`)
  const pcImage = require(`images/pc/${props.image}.png`)
  return (
    <div className={`${styles.strongPointCard}`}>
      <SP>
        <div className={styles.imageWrapper}>
          {props.index === 0 && (
            <div className={styles.image_bg} />
          )}
          <img
            className={styles[`image_${props.index}`]}
            src={spImage}
            alt={props.image}
          />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.head}>
            <div>{props.title}</div>
            <div className={styles.subtitle}>
              <span />
              <p>{`理由：${props.subTitle}`}</p>
            </div>
          </div>
          <div className={styles.content}>
            <p className={styles.description}>
              {props.description}
            </p>
            {props.children}
          </div>
        </div>
      </SP>
      <PC>
        <div className={styles.wrapper}>
          <div className={styles.head}>
            <div className={styles.imageBlock}>
              <div className={styles.imageWrapper}>
                {props.index === 0 && (
                  <div className={styles.image_bg} />
                )}
                <img
                  className={styles[`image_${props.index}`]}
                  src={pcImage}
                  alt={props.image}
                />
              </div>
            </div>
            <div>{props.title}</div>
            <div className={styles.subtitle}>
              <span />
              <p>{`理由：${props.subTitle}`}</p>
            </div>
          </div>
          <div className={styles.content}>
            <p className={styles.description}>
              {props.description}
            </p>
            {props.children}
          </div>
        </div>
      </PC>
    </div>
  )
}

export default StrongPointCard
