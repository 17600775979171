import * as React from 'react'
import 'scss/horizontalLine.scss'

type HLProps = {
  size: string
  color?: string
  width: string
  children: React.ReactNode
}

const HL: React.FC<HLProps> = (props) => {
  HL.defaultProps = {
    color: '#063757',
  }
  const border = `${props.size} solid ${props.color}`

  return (
    <div className="wrapper ma-2">
      <span
        className="title-border-before"
        style={{
          borderTop: border,
          minWidth: props.width,
        }}
      />
      <div className="title-border">{props.children}</div>
      <span
        className="title-border-after"
        style={{
          borderTop: border,
          minWidth: props.width,
        }}
      />
    </div>
  )
}
export default HL
