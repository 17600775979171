import * as React from 'react'
import { SP, PC } from '../utils/media-query'
import HL from '../atoms/HorizontalLine'
import StrongPointCard from '../molecules/StrongPointCard'
import EstimateList from '../molecules/EstimateList'
import Balloon from '../atoms/Balloon'
import MyBorder from '../atoms/MyBorder'
import Button from '../atoms/TheButton'

const styles = require('scss/strongPoint.module.scss')

const headImage1 = require(`images/pc/strong_point_3.png`)
const headImage2 = require(`images/pc/strong_point_4.png`)

const contents = [
  {
    developmentCost: '300万円',
    costOfChange: '10万円',
    redevelopmentCost: '20万円',
    totalCost: '330万円',
  },
  {
    developmentCost: '200万円',
    costOfChange: '100万円',
    redevelopmentCost: '150万円',
    totalCost: '450万円',
  },
]

const cardTitles = [
  <>
    <SP>
      <h2 className="mb-4">費用を安く抑えられる</h2>
    </SP>
    <PC>
      <h2 className="mt-0">費用を安く抑えられる</h2>
    </PC>
  </>,
  <>
    <h2 className="mt-1">最善な仕様や要件・システムを</h2>
    <br />
    <SP>
      <h2 className="mb-4">一緒に定義できる</h2>
    </SP>
    <PC>
      <h2>一緒に定義できる</h2>
    </PC>
  </>,
  <>
    <SP>
      <h2 className="mb-4">システムの品質が安定している</h2>
    </SP>
    <PC>
      <h2 className="mt-0">システムの品質が安定している</h2>
    </PC>
  </>,
]

const spCardSubTitles = [
  '常に最新技術をキャッチアップしているからです。',
  '着手前のヒアリングを\n徹底しているからです。',
  '少数精鋭のチームだからです。',
]

const pcCardSubTitles = [
  '常に最新技術をキャッチアップ\nしているからです。',
  '着手前のヒアリングを徹底しているからです。',
  '少数精鋭のチームだからです。',
]

const cardDescriptions = [
  'システムの費用対効果は、開発費用だけでなく「維持費」にも大きく依存することをご存知ですか？\n「維持費」を最小限に抑えるには「最新技術による開発」が必要です。\n\n例えば開発後によく「やっぱり機能を変更したい」「この機能を追加したい」といった仕様変更のご要望をいただくことがありますが、最新技術を用いた開発であれば柔軟な機能変更が可能になるため、再開発コストやメンテナンス費用が大幅に削減されるのです。\n\n最初の費用は決してお安くないかもしれませんが、長期的にみたときに高いコストパフォーマンスを発揮するシステムを提供します。',
  '弊社では、いただいたご要望のシステム開発を単に行うだけではありせん。\n着手前に必ず「システムにより解決したい問題の本質は何か」を丁寧にヒアリングいたします。\n\nこのステップを踏まえることで、いただいた一つのご要望から、それを解決しうる複数のソリューションやご要望を上回るシステムをご提案できます。\n',
  '大きな会社では複数の開発チームが存在しているため、同じ会社に依頼しても担当されるチームは疎らになることが多く、その分システムの品質も安定しないケースがあります。\n\nしかし、弊社ニチコマでは少数精鋭のチーム体制なので、いつ、どんなタイミングでご依頼いただいても、安定した品質でご提供差し上げます。\nまた、少数精鋭だからこそ小回りの効いた開発やスムーズなコミュニケーションも可能です。',
]

const spMyBorderMessage =
  '維持費のコストカットを\n実現した開発の実例'

const pcMyBorderMessage =
  '維持費のコストカットを実現した開発の実例'

const spBalloonMessage = '大幅カット！'
const pcBalloonMessage = '大幅\nカット！'

class StrongPoint extends React.Component {
  render() {
    return (
      <section className={styles.strongPoint}>
        <div className={styles.head}>
          <div className={styles.imageWrapper}>
            <img
              className={styles.image_3}
              src={headImage1}
              alt="strong_point_bg"
            />
            <img
              className={styles.image_4}
              src={headImage2}
              alt="strong_point_bg"
            />
          </div>

          <HL
            size="1px"
            color="white"
            width="clamp(3rem, 10vw, 5rem)"
          >
            <PC>
              <h1 className="mx-4">
                ニチコマの強みとその理由
              </h1>
            </PC>
            <SP>
              <h1 className="mx-4">
                {`ニチコマの強み\nとその理由`}
              </h1>
            </SP>
          </HL>
        </div>
        <div className={styles.content}>
          {cardTitles.map((title, index) => {
            return (
              <div key={index}>
                <SP>
                  <StrongPointCard
                    title={title}
                    subTitle={spCardSubTitles[index]}
                    description={cardDescriptions[index]}
                    image={`strong_point_${index}`}
                    index={index}
                  >
                    {index === 0 && <CostReduction />}
                  </StrongPointCard>
                </SP>
                <PC>
                  <StrongPointCard
                    title={title}
                    subTitle={pcCardSubTitles[index]}
                    description={cardDescriptions[index]}
                    image={`strong_point_${index}`}
                    index={index}
                  >
                    {index === 0 && <CostReduction />}
                  </StrongPointCard>
                </PC>
              </div>
            )
          })}
          <div className={styles.action}>
            <PC>
              <Button
                to="/achievements"
                type="ellipse"
                size="medium"
                icon
                color="secondary"
              >
                開発実績・料金表はこちら
              </Button>
            </PC>
            <SP>
              <Button to="/achievements" size="responsive">
                開発実績・料金表はこちら
              </Button>
            </SP>
          </div>
        </div>
      </section>
    )
  }
}

const CostReduction = () => {
  return (
    <>
      <div className={styles.listTitle}>
        <SP>
          <MyBorder
            message={spMyBorderMessage}
            color="#063757"
          />
        </SP>
        <PC>
          <MyBorder
            message={pcMyBorderMessage}
            color="#063757"
          />
        </PC>
      </div>
      <div className={styles.estimateList}>
        <EstimateList contents={contents}></EstimateList>
        <SP>
          <div className={styles.balloon}>
            <Balloon message={spBalloonMessage} />
          </div>
        </SP>
        <PC>
          <Balloon message={pcBalloonMessage} />
        </PC>
      </div>
      <div className={styles.annotation}>
        <SP>
          <pre>
            {`維持費を抑える開発により、\n年間あたりのコストを大幅にカットできます。`}
          </pre>
        </SP>
        <PC>
          <pre>
            {`維持費を抑える開発により、年間あたりのコストを大幅にカットできます。`}
          </pre>
        </PC>
      </div>
    </>
  )
}

export default StrongPoint
