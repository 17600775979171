import * as React from 'react'
import { PC, SP } from '../utils/media-query'
import Button from '../atoms/TheButton'
const styles = require('scss/top.module.scss')

export default class Top extends React.Component {
  render() {
    return (
      <section className={styles.top}>
        <div className={styles.container}>
          {Array(4)
            .fill(null)
            .map((_, index) => {
              const spImage = require(`images/sp/top_${index}.png`)
              const pcImage = require(`images/pc/top_${index}.png`)
              return (
                <picture key={index}>
                  <source
                    media="(max-width: 768px)"
                    srcSet={spImage}
                    className={styles[`img${index}`]}
                  />
                  <img
                    src={pcImage}
                    alt={`top-img-${index}`}
                    className={styles[`img${index}`]}
                  />
                </picture>
              )
            })}

          <div className={styles.inner}>
            <div className={styles.title}>
              <h1>
                開発から要件のご相談まで
                <br />
                <span className={styles['dot']}>全て</span>
                お任せください。
              </h1>
            </div>
            <div className={styles['action']}>
              <PC>
                <Button icon to="#contact">
                  ご相談はこちら
                </Button>
              </PC>
              <SP>
                <Button
                  icon
                  to="#contact"
                  size="responsive"
                >
                  ご相談はこちら
                </Button>
              </SP>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
