import * as React from 'react'
const styles = require('scss/balloon.module.scss')
export interface BalloonProps {
  message: string
}

const Balloon = (props: BalloonProps) => {
  return (
    <div className={`${styles.balloon} ml-3`}>
      <span className={styles['left']} />
      <div className={styles.flexibleBox}>
        <pre>{props.message}</pre>
      </div>
    </div>
  )
}

export default Balloon
