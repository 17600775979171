import * as React from 'react'
const style = require('scss/problemsCard.module.scss')

type Props = {
  image: string
  text: string
}
const ProblemsCard = (props: Props) => {
  return (
    <div className={style.problemsCard}>
      <img
        src={props.image}
        alt={props.image}
        className={style.image}
      />
      <div className={style.textWrapper}>
        <p className={style.text}>{props.text}</p>
      </div>
    </div>
  )
}
export default ProblemsCard
