import * as React from 'react'
import ImageCard from '../molecules/ImageCard'
import { SP, PC } from '../utils/media-query'
const style = require('scss/aboutUs.module.scss')
const data = [
  {
    title: '代表メッセージ',
    to: '/ceo-message',
  },
  {
    title: '合同会社とは？',
    to: '/about-llc',
  },
  {
    title: '企業概要',
    to: '/company-profile',
  },
  {
    title: '採用情報',
    to: '/recruit',
  },
]
class AboutUs extends React.Component {
  render() {
    return (
      <section className={style.aboutUsContainer}>
        <h2 className={style.title}>私たちについて</h2>
        <div className={style.bottomContainer}>
          <div className={style.imageCardContainer}>
            {Array(4)
              .fill(null)
              .map((_, index) => {
                const spImage = require(`images/sp/about_us_${index}.png`)
                const pcImage = require(`images/pc/about_us_${index}.png`)
                const spWebP = require(`images/sp/webp/about_us_${index}.webp`)
                return (
                  <React.Fragment key={`aboutUs-${index}`}>
                    <SP>
                      <div
                        className={style.card + ' m-2'}
                        key={spImage}
                      >
                        <ImageCard
                          title={data[index].title}
                          image={pcImage}
                          to={data[index].to}
                          key={spImage}
                        />
                      </div>
                    </SP>
                    <PC>
                      <div
                        className={style.card}
                        key={pcImage}
                      >
                        <ImageCard
                          title={data[index].title}
                          image={pcImage}
                          width={700}
                          height={200}
                          to={data[index].to}
                          key={pcImage}
                        />
                      </div>
                    </PC>
                  </React.Fragment>
                )
              })}
          </div>
        </div>
      </section>
    )
  }
}
export default AboutUs
